export default{
    computed:{
        isImageFile(){
            return (ext) => ['jpeg','png','webp','jpg','svg'].includes(ext ? ext.toLowerCase() : '')
        },
    },
    methods:{
        absoluteUrl(path = ""){
            return this.$store.state.config.domain+path
        },
        calculateDeliveryTime(time){
            return time > 24
               ?  `${time/24} Days`
               : `${time} Hrs`
        },
        preText(content) {
            if(!content) return;
            return content.replace(/\n/g, '<br />')
        },
        scrollToView(elID){
            if(!elID){return}
            const yOffset = -200;
            const element = document.getElementById(elID);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        },
        alertSuccess(text){
            this.$store.dispatch("alertSuccess", text)
        },
        alertError(text){
            this.$store.dispatch("alertError", text)
        },
    },
}
