export default [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: '/main',
    redirect: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@views/Dashboard.vue'),
        meta: {
          title: "Dashboard",
          authRequired: true,
        }
      },
      {
        path: '/orders',
        name: 'order',
        component: () => import('@views/orders/OrderList.vue'),
        meta: {
          title: "Orders List",
          authRequired: true,
        },
      },
      {
        path: '/orders/:orderId(\\d+)',
        name: 'order-view',
        redirect: to => {
          return { path: `/orders/${to.params.orderId}/overview` }
        },
        meta: {
          pageTitle: "Vew Order",
          authRequired: true,
        },
      },
      {
        path: '/orders/:orderId(\\d+)/overview',
        name: 'order-overview',
        component: () => import('@views/orders/OrderOverview.vue'),
        meta: {
          title: "Order Overview",
          authRequired: true,
        },
      },
      {
        path: '/activity',
        name: 'activity',
        component: () => import('@views/activity/ActivityList.vue'),
        meta: {
          title: "Activity",
          authRequired: true,
        }
      },
      {
        path: '/settings/profile',
        name: 'profile',
        component: () => import('@views/settings/account/UserEdit.vue'),
        meta: {
          title: 'Profile',
          authRequired: true,
        },
      },
      {
        path: '/settings/notifications',
        name: 'notifications',
        component: () => import('@views/settings/Notifications.vue'),
        meta: {
          title: 'Notifications',
          authRequired: true,
        },
      },     
    ]
  },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
  {
    path: '/full',
    component: () => import('@/layouts/Full.vue'),
    children: [      
      {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/pages/Error404.vue'),
        meta: {
          title: "404 Error",
        }
      },
      {
        path: '/error-500',
        name: 'error-500',
        component: () => import('@/views/pages/Error500.vue'),
        meta: {
          title: '500 Error',
        }
      },
      {
        path: '/auth',
        name: 'auth',
        component: () => import('@/views/pages/Authenticate.vue'),
        meta:{
          title: "Auto Login"
        }
      },
    ]
  },
  // Redirect to 404 page, if no match found
  {
    path: "/:any(.*)",
    redirect: '/error-404'
  },
];